import React from "react"
import Layout from "../components/layout"
import Table from "react-bootstrap/Table"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col";
const aboutPage = () => (
  <Layout>
    <Row>
      <Col>
      <h1>SONAM CHEMICAL INDUSTRIES</h1>
      <p>
        Being a sister concern of Threesons Paints & Chemicals Pvt. Ltd., We
        introduce ourselves as a leading Business house for marketing all kinds
        of industrial chemicals and pigments throughout South India. We are in
        the market for the last 35 years supplying chemicals to various
        industries such as Rubber, Plastics, Paints, Textiles, Battery
        manufacturers, Printing Ink etc. Our wide range of products mingled with
        our ever so enthusiastic marketing team has always ensured the delivery
        of quality products on time.
      </p>
      </Col>
      
    </Row>

    <Row>
      <Col>
      <h3 style={{textAlign : 'center'}}>List of Sonam Chemical Industries Products</h3>
      <Table striped bordered variant responsive>
        <tbody>
          <tr>
            <td>Amonium Bicarbonate</td>
            <td>Acetic Acid</td>
            <td>Amonium Bichromate</td>
            <td>Amonium Chloride</td>
          </tr>
          <tr>
            <td>Amonium Phosphate</td>
            <td>Amonium Nitrate</td>
            <td>Aluminium Stearate</td>
            <td>Acid Slurry</td>
          </tr>
          <tr>
            <td>Antimony Tri Oxide</td>
            <td>Liquor Ammonia</td>
            <td>Acetone</td>
            <td>Borax Crystal/Granular</td>
          </tr>
          <tr>
            <td>Boric Acid</td>
            <td>Barium Chloride</td>
            <td>Barium Carbonate</td>
            <td>Barium Nitrate</td>
          </tr>
          <tr>
            <td>Barium Sulphate</td>
            <td>Butly Cellosolve</td>
            <td>Benzoic Acid</td>
            <td>Benzene</td>
          </tr>
          <tr>
            <td>Chromin Acid</td>
            <td>Carbon Tetra chloride</td>
            <td>Cyclohexalnone</td>
            <td>Cyclonexanol</td>
          </tr>
          <tr>
            <td>Caustic Soda Flakes</td>
            <td>Caustic Potash Flakes</td>
            <td>Chrome Oxide Green</td>
            <td>Chloroform</td>
          </tr>
          <tr>
            <td>Copper Sulphate</td>
            <td>Carbon Black</td>
            <td>Calcium Chloride Fused</td>
            <td>Calcium Chloride Solid</td>
          </tr>
          <tr>
            <td>Calcium Carbonate</td>
            <td>Citric Acid</td>
            <td>Calcium Silicate</td>
            <td>Calcium Stearate</td>
          </tr>
          <tr>
            <td>D.A.P</td>
            <td>D.O.P</td>
            <td>D.B.P</td>
            <td>Di- Acetone Alcohol</td>
          </tr>
          <tr>
            <td>Di- Ethylene Glycol</td>
            <td>Di-CalciumPhosphate</td>
            <td>Di- Amonium Phosphate</td>
            <td>Ethlyl Acetate</td>
          </tr>
          <tr>
            <td>E.D.T.A</td>
            <td>Formic Acid</td>
            <td>Formaldehyde</td>
            <td>Ferric Chloride</td>
          </tr>
          <tr>
            <td>Ferrous Sulphate</td>
            <td>Hyflosupercell</td>
            <td>Hydrogen peroxide</td>
            <td>Hexamine</td>
          </tr>
          <tr>
            <td>Hydrochloric Acid</td>
            <td>Hypo</td>
            <td>Isopropyl Alcohol</td>
            <td>Litharge</td>
          </tr>
          <tr>
            <td>Lead Acetate</td>
            <td>M.E.K</td>
            <td>Magnesium Sulphate</td>
            <td>M.I.B.K</td>
          </tr>
          <tr>
            <td>Manganese Sulphate</td>
            <td>Magnesium Chloride</td>
            <td>Magnesium Stearate</td>
            <td>Magnesium Oxide</td>
          </tr>
          <tr>
            <td>Methanol</td>
            <td>Nickel Sulphate</td>
            <td>Nickel Chloride</td>
            <td>Nickel Carbonate</td>
          </tr>
          <tr>
            <td>Nitro Benzene</td>
            <td>N.C Laquer</td>
            <td>N.C Thinner</td>
            <td>Nitric Acid</td>
          </tr>
          <tr>
            <td>Oxalic Acid</td>
            <td>O-PhosphoricAcid</td>
            <td>Potassium  Sulphate</td>
            <td>Potassium  Bichromate</td>
          </tr>
          <tr>
            <td>Potassium  Hydroxide</td>
            <td>Propylene Glycol</td>
            <td>Potash – Alum</td>
            <td>Potassium  Silicate</td>
          </tr>
          <tr>
            <td>Potassium  Permangenet</td>
            <td>Phenol Crystal</td>
            <td>Potassium  Nitrate</td>
            <td>Red Oxide</td>
          </tr>
          <tr>
            <td>Sodium Bisulphite</td>
            <td>Soda Ash/ RB</td>
            <td>Sodium Bichromate</td>
            <td>Sodium Sulphide Flakes</td>
          </tr>
          <tr>
            <td>Sodium Sulphide Bits</td>
            <td>Sodium Hydroxide</td>
            <td>Sodium Chromate</td>
            <td>Sodium Tripoly Phosphate</td>
          </tr>
          <tr>
            <td>Sodium Hexameta Phosphate</td>
            <td>Stearic Acid R.G / P.G</td>
            <td>Sodium Acetate</td>
            <td>Sulphuric Acid</td>
          </tr>
          <tr>
            <td>Sodium Silico Flouride</td>
            <td>Sodium Sulphate</td>
            <td>Sodium Nitrate</td>
            <td>Sodium Nitrite</td>
          </tr>
          <tr>
            <td>Silicon Emulsion</td>
            <td>Sorbitol</td>
            <td>Stearic Acid</td>
            <td>Toulene</td>
          </tr>
          <tr>
            <td>Tri Sodium Phosphate</td>
            <td>Titanium Dioxide</td>
            <td>Tri Ethanolamine</td>
            <td>Tri Cholroethylene</td>
          </tr>
          <tr>
            <td>Turkey Red Oil</td>
            <td>Xylene</td>
            <td>Yellow Oxide</td>
            <td>Zinc Acetate</td>
          </tr>
          <tr>
            <td>Zinc Chloride</td>
            <td>Zinc Sulphate</td>
            <td>Zinc Oxide</td>
            <td>Zinc Stearate</td>
          </tr>
        </tbody>
      </Table>
      </Col>
    </Row>
  </Layout>
)

export default aboutPage
